<template>
  <div
    class="advertisers-control"
  >
    <div
      v-if="hasAuthorization.agency"
      class="agency outlined rounded mb-6"
    >
      <v-combobox
        v-model="controller.agency"
        :prefix="$tc('general.agency', 1)"
        :items="agencies"
        item-value="id"
        item-text="name"
        auto-select-first
        hide-details
        solo
        flat
        autocomplete="off"
        :disabled="disabled||agency"
        ref="agency"
        class="agency-field py-1 pl-1"
      >
        <template v-slot:item="data">
          <span 
            class="text-overline"
          >
            {{ data.item.name }}
          </span>
        </template>
      </v-combobox>
    </div>

    <div
      v-if="hasAuthorization.contracts"
      class="contracts outlined rounded mb-6"
    >
      <v-subheader 
        class="text-overline black--text pl-4 pr-2"
        style="height: 56px;"
      >
        {{ $tc('general.contract', Infinity) }}
        <v-badge
          v-show="countContracts>0"
          inline
          :content="countContracts"
          color="grey lighten-1"
          class="ml-1"
        />
        <v-spacer />
        <v-btn
          text
          small
          plain
          :disabled="disabled"
          class="px-2"
          @click="addContract"
        >
          {{ $tc('general.add') }}
        </v-btn>
      </v-subheader>
      <v-divider v-if="hasContracts" />
      
      <v-list
        v-if="hasContracts"
        max-height="320"
        class="scrollable"
      >
        <v-list-item
          v-for="(contract, c) in filteredContracts"
          :key="'contract-'+c"
          class="pr-3"
          @click="selectContract(c)"
        >
          <v-list-item-content>
            <v-list-item-title class="text-overline">
              {{ contractTitle(contract) }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon size="20" color="grey">{{ icons.next }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-show="hasPastContracts"
          class="pr-3"
          @click="view.contracts.past=!view.contracts.past"
        >
          <v-list-item-content>
            <v-list-item-title class="text-overline grey--text">
              {{ view.contracts.past ? $t('general.hide') : $t('general.see') }} {{ $t('brands.contracts.past_contracts') }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon size="20" color="grey">{{ view.contracts.past ? icons.up : icons.down }}</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list>
    </div>
    
    <div
      v-if="hasAuthorization.users"
      class="users outlined rounded mb-6"
    >
      <users-control 
        :users="controller.users"
        :suggestions="users"
        :profiles="profileOptions"
        :disabled="disabled"
        @update="(value) => updateField('users', value)"
      />
    </div>

    <v-btn
      v-if="hasAuthorization.delete"
      text
      small
      :disabled="disabled||!isNew"
      color="error"
      class="mt-2"
      @click="deleteAdvertiser"
    >
      <v-icon left small>{{ icons.delete }}</v-icon>
      Remover Anunciante
    </v-btn>
  </div>
</template>

<style lang="scss">

.users-control .add-field input, .users-control .add-field label {
  font-size: 0.9375rem !important;
}

</style>

<script>

  import { 
    mdiChevronRight,
    mdiChevronDown,
    mdiChevronUp,
    mdiTrashCan
  } from '@mdi/js';

  export default {
    props: {
      selected: {
        type: [Number, String],
        default: null
      },
      data: {
        type: Object,
        default: () => null
      },
      agencies: {
        type: Array,
        default: () => []
      },
      brand: {
        type: String,
        default: 'Marca'
      },
      agency: {
        type: Boolean,
        default: false
      },
      users: {
        type: Array,
        default: () => []
      },
      roles: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      admin: {
        type: Boolean,
        default: false
      },
    },
    
    data: () => ({
      icons: {
        next: mdiChevronRight,
        delete: mdiTrashCan,
        down: mdiChevronDown,
        up: mdiChevronUp,
      },
      controller: {
        agency: null,
        contracts: {},
        users: [],
      },
      view: {
        contracts: {
          past: false,
        }
      },
      authorization: {
        agency: [1,5,17],
        contracts: [1,5,17],
        users: [1,5,17],
        delete: [1,5,17],
      },
    }),

    computed: {
      isNew () {
        return this.data!=null&&this.data.id==null;
      },
      filteredContracts () {
        const contracts = this.view.contracts.past ? this.controller.contracts : _.omitBy(this.controller.contracts, contract => this.$moment().isAfter(contract.period.end));
        // return _.orderBy(contracts, ['period.end', 'period.start', 'provisioned'], ['desc', 'desc', 'desc']);
        return contracts;
      },
      countContracts () {
        return _.size(this.controller.contracts);
      },
      hasContracts () {
        return this.countContracts>0;
      },
      hasPastContracts () {
        return _.size(_.filter(this.controller.contracts, contract => this.$moment().isAfter(contract.period.end)))>0;
      },
      hasAuthorization () {
        const roles = this.roles;
        const authorization = this.authorization;
        const grants = _.mapValues(authorization, action => {
          return _.intersection(action, roles)>0;
        })
        return grants;
      },
      profileOptions () {
        let profiles = { 7: { id: 7, title: this.brand }}
        if (this.data!=null&&this.data.agency!=null) profiles[6] = {
          id: 6,
          title: this.data.agency.name
        };
        return profiles;
      }
    },

    watch: {
      data: {
        immediate: true,
        deep: true,
        handler (data) {
          console.log('advertiser', data);
          _.each(_.clone(data), (field, f) => {
            this.controller[f] = _.clone(field);
          })
        }
      },
      controller: {
        immediate: true,
        deep: true,
        handler (data) {
          const fields = _.clone(data);
          let changed = false;
          _.each(fields, (d, f) => {
            if (!_.isEqual(this.data[f], d)) console.log('changed', f, this.data[f], d);
            changed = !changed ? !_.isEqual(this.data[f], d) : changed;
          })
          if (changed) this.update()
        }
      },
    },

    methods: {
      update () {
        this.$emit('update', _.cloneDeep(this.controller));
      },

      updateField (field, value) {
        this.controller[field] = value;
      },

      addContract () {
        this.$emit('add-contract');
      },

      selectContract (i) {
        this.$emit('select-contract', i);
      },
      
      deleteAdvertiser () {
        this.$emit('delete-advertiser', this.selected);
      },
      contractTitle (contract) {
        const start = this.$moment(contract.period.start);
        const end = this.$moment(contract.period.end);
        const format = start.year()==end.year() ? 'MMM' : 'MMM/YY';
        return `${this.$numeral(contract.budget).format('$ 0[,]0')} | ${start.format(format)}–${end.format('MMM/YY')}`
      }
    },

    components: {
      UsersControl: () => import('@/components/UsersControl')
    },
  }
</script>